import React, { useState, useEffect } from 'react'
import styles from './filter.module.scss'
import config from './../../config'
import {useSelector, useDispatch} from 'react-redux'
import { IoIosClose } from "react-icons/io";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Card, CardContent, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Slider, Button} from '@material-ui/core';

const FilterCard = ({typeOffices, onHandleFilter, onReRender, onCloseFilter}) => {
	const [filterByHeadQuarterAndPerson, setFilterByHeadQuarterAndPerson] = useState(useSelector(state => state.filter.filterByHeadQuarterAndPerson))
	const [filterByHeadQuarterAndPersonAndTypeOffice, setFilterByHeadQuarterAndPersonAndTypeOffice] = useState(useSelector(state => state.filter.filterByHeadQuarterAndPersonAndTypeOffice))
	const [filterByTypeOffice, setFilterByTypeOffice] = useState(useSelector(state => state.filter.filterByTypeOffice))
	const [filterByTypeOfficeAndHeadQuarter, setFilterByTypeOfficeAndHeadQuarter] = useState(useSelector(state => state.filter.filterByTypeOfficeAndHeadQuarter))
	const dispatch = useDispatch()
	const [filter, setFilter] = useState('')
	const [valuePrice, setValuePrice] = useState();
	const [valueCapacity, setValueCapacity] = useState();
	const [valueSize, setValueSize] = useState();

	const handleFilter = event => {
		setFilter(event.target.value)
		//handleFetchFilter(event)
		onHandleFilter(event.target.value, "Tipo")
	}

	const handleChangeSliderPrice = (event,newValue) => {
		setValuePrice(newValue)
		setValueCapacity(0)
		setValueSize([0,20])
		onHandleFilter(newValue, "Price")
	}

	const handleChangeSliderCapacity = (event,newValue) => {
		setValueCapacity(newValue)
		setValuePrice([0,800])
		setValueSize([0,20])
		onHandleFilter(newValue, "Capacity")
	}

	const handleChangeSliderSize = (event,newValue) => {
		setValueSize(newValue)
		setValueCapacity([0,30])
		onHandleFilter(newValue, "Size")
	}

	const marksPrice = [{
	    value: 0,
	    label: '$0',
	  },
	  {
	    value: 800,
	    label: '$800.000',
	  }
  	];

	const marksPerson = [{
		value: 1,
		label: '1 Persona',
	  },
	  {
		value: 30,
		label: '+30',
	  }
	];

	const marksSize = [{
		value: 2,
		label: '2 m²',
	  },
	  {
		value: 20,
		label: '+20 m²',
	  }
	];

	const handleRemoveFilterApplied = () => {
		dispatch({type: 'filter/SET_ACTUAL_FILTER_HEADQUARTER_AND_PERSON', data: false})
		dispatch({type: 'filter/SET_ACTUAL_FILTER_HEADQUARTER_AND_PERSON_AND_TYPE_OFFICE', data: false})
		dispatch({type: 'filter/SET_ACTUAL_TYPE_OFFICE', data: false})
		dispatch({type: 'filter/SET_ACTUAL_TYPE_OFFICE_AND_HEAD_QUARTER', data: false})
		setFilterByHeadQuarterAndPerson(false)
		setFilterByHeadQuarterAndPersonAndTypeOffice(false)
		setFilterByTypeOffice(false)
		setFilterByTypeOfficeAndHeadQuarter(false)
		onReRender(Math.random())
	}


	return(
		<div>
			{
				filterByHeadQuarterAndPerson ? (
					<Card className={styles.didFilterContainer}>
						<h4>Filtros aplicados</h4>
						<IoIosClose onClick={handleRemoveFilterApplied} color="red" size={40} className={styles.removeFilterIcon}/>
						<hr/>
						<p>Sede: <span>{filterByHeadQuarterAndPerson.sede}</span></p>
						<p>Capacidad: <span>{filterByHeadQuarterAndPerson.person.split(' ')[filterByHeadQuarterAndPerson.person.split(' ').length-1]}</span></p>
					</Card>
				):(
					filterByHeadQuarterAndPersonAndTypeOffice ? (
						<Card className={styles.didFilterContainer}>
							<h4>Filtros aplicados</h4>
							<IoIosClose onClick={handleRemoveFilterApplied} color="red" size={40} className={styles.removeFilterIcon}/>
							<hr/>
							<p>Sede: <span>{filterByHeadQuarterAndPersonAndTypeOffice.sede}</span></p>
							<p>Capacidad: <span>{filterByHeadQuarterAndPersonAndTypeOffice.person.split(' ')[filterByHeadQuarterAndPersonAndTypeOffice.person.split(' ').length-1]}</span></p>
							<p>Tipo de espacio: <span>{filterByHeadQuarterAndPersonAndTypeOffice.type}</span></p>
						</Card>
					): (
						filterByTypeOffice ? (
							<Card className={styles.didFilterContainer}>
								<h4>Filtros aplicados</h4>
								<IoIosClose onClick={handleRemoveFilterApplied} color="red" size={40} className={styles.removeFilterIcon}/>
								<hr/>
								<p>Tipo de espacio: <span>{filterByTypeOffice}</span></p>
							</Card>
						): (
							filterByTypeOfficeAndHeadQuarter ? (
								<Card className={styles.didFilterContainer}>
									<h4>Filtros aplicados</h4>
									<IoIosClose onClick={handleRemoveFilterApplied} color="red" size={40} className={styles.removeFilterIcon}/>
									<hr/>
									<p>Sede: <span>{filterByTypeOfficeAndHeadQuarter.sede}</span></p>
									<p>Tipo de espacio: <span>{filterByTypeOfficeAndHeadQuarter.typeOffice}</span></p>
								</Card>
							): (
								null
							)
						)
					)
				)
			}
			<Card className={styles.container}>
				<h3>Filtrar</h3>
				<hr/>
				<div className={styles.formContainer}>
					<div className={styles.miniContainer}>
						<h3>Precio</h3>
						<Slider
							className = {styles.sliderColor}
							value={valuePrice}
							defaultValue={[0,800]}
							max={800}
							marks={marksPrice}
							min={0}
							step={100}
					        onChange={handleChangeSliderPrice}
					        valueLabelDisplay="auto"
					        aria-labelledby="range-slider"
			    		/>
					</div>
					<div className={styles.miniContainer}>
						<h3>Capacidad</h3>
						<Slider
							className = {styles.sliderColor}
							value={valueCapacity}
							defaultValue={[0,30]}
							max={30}
							marks={marksPerson}
							disabled={filterByHeadQuarterAndPerson || filterByHeadQuarterAndPersonAndTypeOffice}
							min={1}
							step={2}
							onChange={handleChangeSliderCapacity}
							valueLabelDisplay="auto"
							aria-labelledby="range-slider"
						/>
					</div>
					<div className={styles.miniContainer}>
						<h3>Tamaño</h3>
						<Slider
							className = {styles.sliderColor}
							value={valueSize}
							defaultValue={[0,20]}
							max={20}
							marks={marksSize}
							min={2}
							step={2}
							onChange={handleChangeSliderSize}
							valueLabelDisplay="auto"
							aria-labelledby="range-slider"

						/>
					</div>
					<div className={styles.miniContainer}>
						<h3>Tipo de espacio</h3>
						<RadioGroup
						  className={styles.radioContainer}
						  name="filter"
						  value={filter}
						  onChange={handleFilter}
						>
						{
							typeOffices.map((el,idx)=>(
								<FormControlLabel disabled={filterByHeadQuarterAndPersonAndTypeOffice || filterByTypeOffice || filterByTypeOfficeAndHeadQuarter} key={idx} className={styles.labelRadio} value={el.name} control={<Radio />} label={<p className={styles.labelText}>{el.name}</p>} />
							))
						}
						</RadioGroup>
						<Button onClick={onCloseFilter}
							style = {{
								marginLeft: "0.875rem",
								textTransform: "capitalize",
								width: "50%",
								borderRadius: "0.7em",
								borderWidth: "0.125rem",
								borderColor: "#DE892A",
								color: "#fff",
								backgroundColor: "#DE892A",
								fontWeight: "600",
								fontSize: "1rem",
								textTransform: "uppercase",
								fontFamily: 'Barlow'
							  }}>
							Aplicar
						</Button>
					</div>
				</div>
			</Card>
		</div>
	)
}


export default FilterCard