import React, {useState, useEffect} from 'react'
import styles from './styles/filter.module.scss'
import Layout from "../components/Layout"
import HeaderBack from "../components/HeaderBack"
import SpaceBoxFilter from "../components/SpaceBoxFilter"
import FilterCard from "../components/FilterCard"
import {Link} from 'gatsby'
import SEO from "../components/seo"
import config from './../config'
import Lottie from 'react-lottie';
import ReactPaginate from 'react-paginate';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import {useSelector, useDispatch} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import DraftsIcon from '@material-ui/icons/Drafts';


const useStyles = makeStyles({
	list: {
	  width: 350,
	},
	fullList: {
	  width: 'auto',
	},
	card: {
		maxWidth: 345,
	  },
	  media: {
		height: 200,
		width: 350
	  },
  });

const Filter = () => {
	const filterByHeadQuarterAndPerson = useSelector(state => state.filter.filterByHeadQuarterAndPerson)
	const filterByHeadQuarterAndPersonAndTypeOffice = useSelector(state => state.filter.filterByHeadQuarterAndPersonAndTypeOffice)
	const filterByTypeOffice = useSelector(state => state.filter.filterByTypeOffice)
	const filterByTypeOfficeAndHeadQuarter = useSelector(state => state.filter.filterByTypeOfficeAndHeadQuarter)
	const [offices, setOffices] = useState()
	const [typeOffices, setTypeOffices] = useState()
	const [pageCount, setPageCount] = useState()
	const [filterValueToFetch, setFilterValueToFetch] = useState(false)
	const [filterByToFetch, setFilterByToFetch] = useState(false)
	const [isFilter, setIsFilter] = useState(false)
	const [newForcePage, setNewForcePage] = useState(0)
	const [defaultState, setDefaultState] = useState(1);
	const dispatch = useDispatch()

	const classes = useStyles();

	useEffect(() => {
		getAllOffices()
		getTypeOffices()

		return () => {
			dispatch({type: 'filter/SET_ACTUAL_FILTER_HEADQUARTER_AND_PERSON', data: false})
			dispatch({type: 'filter/SET_ACTUAL_FILTER_HEADQUARTER_AND_PERSON_AND_TYPE_OFFICE', data: false})
			dispatch({type: 'filter/SET_ACTUAL_TYPE_OFFICE', data: false})
			dispatch({type: 'filter/SET_ACTUAL_TYPE_OFFICE_AND_HEAD_QUARTER', data: false})
		}

	},[defaultState])

	const test = ()=>{
		console.log("entro")
	}

	const getAllOffices = async (page) => {
		
		if(page) setNewForcePage(page.selected)
		if(!isFilter){
			let url;
			if (filterByHeadQuarterAndPerson){
				url = page ? (`${config.server}/getAllOffices?page=${page.selected+1}&filterByHeadQuarterAndPerson=${JSON.stringify(filterByHeadQuarterAndPerson)}`):(`${config.server}/getAllOffices?page=1&filterByHeadQuarterAndPerson=${JSON.stringify(filterByHeadQuarterAndPerson)}`)
			}else if(filterByHeadQuarterAndPersonAndTypeOffice){
				url = page ? (`${config.server}/getAllOffices?page=${page.selected+1}&filterByHeadQuarterAndPersonAndTypeOffice=${JSON.stringify(filterByHeadQuarterAndPersonAndTypeOffice)}`):(`${config.server}/getAllOffices?page=1&filterByHeadQuarterAndPersonAndTypeOffice=${JSON.stringify(filterByHeadQuarterAndPersonAndTypeOffice)}`)
			}else if(filterByTypeOffice){
				url = page ? (`${config.server}/getAllOffices?page=${page.selected+1}&filterByTypeOffice=${filterByTypeOffice}`):(`${config.server}/getAllOffices?page=1&filterByTypeOffice=${filterByTypeOffice}`)
			}else if(filterByTypeOfficeAndHeadQuarter){
				url = page ? (`${config.server}/getAllOffices?page=${page.selected+1}&filterByTypeOfficeAndHeadQuarter=${JSON.stringify(filterByTypeOfficeAndHeadQuarter)}`):(`${config.server}/getAllOffices?page=1&filterByTypeOfficeAndHeadQuarter=${JSON.stringify(filterByTypeOfficeAndHeadQuarter)}`)
			}else{
				url = page ? (`${config.server}/getAllOffices?page=${page.selected+1}`):(`${config.server}/getAllOffices?page=1`)
			}

			try {
				const response = await fetch(url, {
					method: 'GET',
					params: {per_page: 10},
					headers: {
						'Content-Type': 'application/json'
					}
				})

				const fetchData = await response.json();
				let newArr = [];
				setOffices([])
				fetchData.offices.map((el,idx)=>{
					if(el.feature_image_uid == null){
						el.feature_image_uid = 'oficinas_placeholder.png'
					}
					newArr.push(el);
					if(idx == fetchData.offices.length-1){
						setOffices(newArr)
					}
					console.log("el",el)
				})
				//setOffices(fetchData.offices)
				setPageCount(fetchData.pages)
			} catch (e) {
				console.log(e)
			}
			finally {
				var element = document.getElementById("espacios_top");
				element.scrollIntoView();
			}
		}else{
			handleFilter(null,null,page)
		}
	}

	const getTypeOffices = async () => {
		try {
			const response = await fetch(`${config.server}/office_types`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const fetchData = await response.json()
			setTypeOffices(fetchData)
		} catch (e) {
			console.log(e)
		}
	}

	const handleFilter = async(newValue,filterBy, page) => {
		let filterVal,filterType;

		if(filterBy == "Tipo"){
			setNewForcePage(0)
			filterVal = newValue
			filterType = filterBy
			setFilterValueToFetch(filterVal)
			setFilterByToFetch(filterBy)
		}else{
			if (newValue){
				setNewForcePage(0)
				filterVal = newValue.join('-')
				filterType = filterBy
				setFilterValueToFetch(filterVal)
				setFilterByToFetch(filterBy)
			}else{
				filterVal = filterValueToFetch
				filterType = filterByToFetch
			}
		}

		setIsFilter(true)

		const newPage = page ? (page.selected+1):(1)
		let url;
		if (filterByHeadQuarterAndPerson){
			url = `${config.server}/getFilterOffices?filterType=${filterType}&filterBy=${filterVal}&page=${newPage}&filterByHeadQuarterAndPerson=${JSON.stringify(filterByHeadQuarterAndPerson)}`
		}else if (filterByHeadQuarterAndPersonAndTypeOffice){
			url = `${config.server}/getFilterOffices?filterType=${filterType}&filterBy=${filterVal}&page=${newPage}&filterByHeadQuarterAndPersonAndTypeOffice=${JSON.stringify(filterByHeadQuarterAndPersonAndTypeOffice)}`
		}else if (filterByTypeOffice){
			url = `${config.server}/getFilterOffices?filterType=${filterType}&filterBy=${filterVal}&page=${newPage}&filterByTypeOffice=${filterByTypeOffice}`
		}else if(filterByTypeOfficeAndHeadQuarter){
			url = `${config.server}/getFilterOffices?filterType=${filterType}&filterBy=${filterVal}&page=${newPage}&filterByTypeOfficeAndHeadQuarter=${JSON.stringify(filterByTypeOfficeAndHeadQuarter)}`
		}else{
			url = `${config.server}/getFilterOffices?filterType=${filterType}&filterBy=${filterVal}&page=${newPage}`
		}

		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const fetchData = await response.json()
			console.log("entro filtro", fetchData)
			let newArrT = [];
			setOffices([])
			fetchData.offices.map((el,idx)=>{
				if(el.feature_image_uid == null){
					el.feature_image_uid = 'oficinas_placeholder.png'
				}
				newArrT.push(el);
				if(idx == fetchData.offices.length-1){
					setOffices(newArrT)
				}
			})
			//setOffices(fetchData.offices)
			setPageCount(fetchData.pages)
		} catch (e) {
			console.log(e)
		}

	}


	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: require('./../animations/3678-fluid-loading-animation.json'),
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	
	const [state, setState] = React.useState({
	  top: false,
	  left: false,
	  bottom: false,
	  right: false,
	});
  
	const toggleDrawer = (side, open) => event => {
	  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
		return;
	  }
	  
	  setState({ ...state, [side]: open });
	}; 
  
	const sideList = side => (
	  <div
		className={classes.list}
		role="presentation"
		/* onClick={toggleDrawer(side, true)}
		onKeyDown={toggleDrawer(side, true)} */
	  >
		<FilterCard typeOffices={typeOffices} onHandleFilter={handleFilter} onReRender={setDefaultState} 
		onCloseFilter={() => setState({ ...state, right: false })}/>
	  </div>
	);
	
	const sup = "2"

	return (<div>
		{
			typeof offices !== 'undefined' && typeof typeOffices !== 'undefined'
				? (<Layout>
					<SEO title="Espacios"/>
					<HeaderBack title="Explorar Espacios & Oficinas" description="COWORK ENTRE TODOS es un centro de Co-Working ubicado en Barranquilla.
							El centro está dedicado a la implementación de espacios compartidos y oficinas privadas para el uso de los clientes." image={require('./../images/espacios.jpg')}/>

					<Grid container spacing={3}>
						<Grid item xs={12}
						style = {{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "2rem",
							marginRight: "2rem"
						}}>
							<Button onClick={toggleDrawer('right', true)}
							style = {{
								marginLeft: "0.875rem",
								textTransform: "capitalize",
								width: "9rem",
								borderRadius: "0.7em",
								borderWidth: "0.125rem",
								borderColor: "#DE892A",
								color: "#fff",
								backgroundColor: "#DE892A",
								fontWeight: "600",
								fontSize: "1rem",
								textTransform: "uppercase",
								fontFamily: 'Barlow'
							  }}>Filtrar</Button>
						</Grid>
					</Grid>
					<div className={styles.container}>
					<div>
						<Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
							{sideList('right')}
						</Drawer>
					</div>
						
						<div className={styles.officeContainer} id = "espacios_top">
							{
								offices.length > 0 ? (
									<div>
										 <Grid container spacing={3}>
									{
										offices.map((el, idx) => (
											
											<Grid item xs={12} md= {6} lg= {4} 
											style = {{padding: "2rem", 
											display: 'flex', justifyContent: 'center', margin: 'auto' }}>
												<Link className={styles.linking} to={`/${el.head_quarter_slug}/${el.slug}`} style = {{color: "#F9C945"}}>
													<Card className={classes.card}>
															<CardMedia
															className={classes.media}
															image= {`${config.assets}/${el.feature_image_uid}`}
															title="Espacio"
															/>
															<CardContent>
																<Typography gutterBottom variant="h5" component="h2">
																	{el.name}
																</Typography>
																<Typography variant="body2" color="textSecondary" component="p">
																	<List component="nav" aria-label="main mailbox folders">
																		<ListItem button>
																			<ListItemText primary={`Sede: ${el.head_quarter_name}`} />
																		</ListItem>
																		<ListItem button>
																			{(el.daily > 0) ? (
																					<ListItemText primary={`Desde: $ ${el.daily.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`} />
																				):(el.weekly > 0) ? (
																					<ListItemText primary={`Desde: $ ${el.weekly.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`} />
																				):(
																					<ListItemText primary={`Desde: $ ${el.monthly.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`} />
																				)
																			}
																			
																		</ListItem>
																		<ListItem button>
																			<ListItemText primary={`Personas: ${el.capacity}`} />
																		</ListItem>
																		<ListItem button>
																			{el.size ? <ListItemText primary={`Tamaño: ${el.size}m2`} /> : <ListItemText primary={`Tamaño: N/A`} />}
																		</ListItem>
																	</List>
																																		
																</Typography>
															</CardContent>
														{/* <CardActions
														style = {{
															display:"flex",
															justifyContent: "center"
														}}>
															<Link size="small" color="primary"
																to = {`/${el.head_quarter_slug}/${el.slug}`}
																style = {{
																	marginBottom: "1rem",
																	textTransform: "capitalize",
																	padding: "0.2rem 0.5rem",
																	borderRadius: "0.7em",
																	borderWidth: "0.925rem",
																	borderColor: "#DE892A",
																	color: "#fff",
																	backgroundColor: "#DE892A",
																	fontWeight: "600",
																	fontSize: "1rem",
																	textTransform: "uppercase",
																	fontFamily: 'Barlow'
																}}>
															Ver espacio
															</Link>
														</CardActions> */}
													</Card>
												</Link>
											</Grid>
										))
									}
									</Grid>

									<ReactPaginate
							          previousLabel={<FaAngleLeft size='1.5em'/>}
							          nextLabel={<FaAngleRight size='1.5em'/>}
							          breakLabel={'...'}
							          breakClassName={styles.pageNumber}
							          pageCount={pageCount}
							          marginPagesDisplayed={2}
							          pageRangeDisplayed={2}
									  containerClassName={styles.paginationContainer}
									  pageClassName={styles.pageNumber}
									  pageLinkClassName={styles.pageLink}
									  previousClassName={styles.pagePrevious}
									  nextClassName={styles.pageNext}
									  forcePage={newForcePage}
									  onPageChange={getAllOffices}
									  activeLinkClassName={styles.activePage}
									  nextLinkClassName={styles.activePage}
									  previousLinkClassName={styles.activePage}
							        />
								</div>
								):(
									<h3 className={styles.noData}>No hay oficinas para mostrar</h3>
								)
							}

						</div>
						{/* <div className={styles.officeFilter}>
							<div className={styles.officeItemFilter}>
								<FilterCard typeOffices={typeOffices} onHandleFilter={handleFilter} onReRender={setDefaultState}/>
							</div>
						</div> */}
					</div>
				</Layout>)
				: (<Lottie options={defaultOptions} height={300} width={300} style={{
						position: 'absolute',
						left: '50%',
						transform: 'translateX(-50%) translateY(-50%)',
						top: '50%'
					}}/>)
		}

	</div>)
}

const spaces = [
	{
		image: require('./../images/bigoffice.png'),
		title: 'Oficina Grande',
		price: '500.000',
		capacity: '5',
		meters: '8',
		sede: 'Sede 84'
	}, {
		image: require('./../images/workingplace.png'),
		title: 'Oifcina Compartida',
		price: '500.000',
		capacity: '5',
		meters: '16',
		sede: 'Sede Prado'
	}, {
		image: require('./../images/smalloffice.png'),
		title: 'Oficina Pequeña',
		price: '200.000',
		capacity: '5',
		meters: '2',
		sede: 'Sede Prado'
	}, {
		image: require('./../images/meeting.png'),
		title: 'Salonde Reuniones',
		price: '500.000',
		capacity: '5',
		meters: '16',
		sede: 'Sede 84'
	}
]

export default Filter
